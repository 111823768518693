import type { ComputedRef } from 'vue'
import type { RouteQueryAndHash } from 'vue-router'

export type NavigationItem = {
    name: string
    to: RouteQueryAndHash
}

export const useIndexNavigationItems = (extraItems: string[] = []): ComputedRef<NavigationItem[]> => {
    const items = [
        'chat',
        'knowledge-base',
        'widget',
        ...extraItems,
    ]

    return computed<NavigationItem[]>(() => items.map(name => ({
        name,
        to: {
            name: 'index',
            hash: '#' + name,
        },
    })))
}
