<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable @stylistic/max-len -->
            <path
                d="M13.904 10.4696L21.3513 2H19.5871L13.1179 9.35251L7.9548 2H1.99829L9.80762 13.1194L1.99829 21.9999H3.76244L10.5897 14.2338L16.0435 21.9999H22M4.39916 3.30158H7.10939L19.5858 20.7623H16.8749"
                :fill="color"
            />

            <!-- eslint-enable @stylistic/max-len -->
        </template>
    </AppIcon>
</template>
