<script
    lang="ts"
    setup
>
    const showSidebar = ref<boolean>(false)
</script>

<template>
    <div class="flex items-center gap-4 tablet:gap-10 ml-auto">
        <TheHeaderActionsLanguage class="max-tablet:hidden" />

        <TheHeaderActionsAuthButtons class="max-tablet:[&>a:last-of-type]:hidden" />

        <TheHeaderActionsSidebarActivator @open="showSidebar = true" />

        <Teleport to="body">
            <Transition name="app-sidebar-transition">
                <TheHeaderActionsSidebar
                    v-if="showSidebar"
                    key="sidebar"
                    @close="showSidebar = false"
                />
            </Transition>
        </Teleport>
    </div>
</template>

<style
    lang="sass"
    scoped
>
    .app-sidebar-transition
        &-enter-from,
        &-leave-to
            opacity: 0
            transform: translateX(50%)

        &-enter-active,
        &-leave-active
            pointer-events: none
            transition: opacity var(--transition-default-duration-with-ease), transform var(--transition-default-duration-with-ease)

        &-enter-to,
        &-leave-from
            opacity: 1
            transform: translateX(0)
</style>
