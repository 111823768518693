<template>
    <div class="flex max-tablet:flex-col items-center max-tablet:items-start tablet:max-desktop:justify-between gap-8">
        <div class="flex max-tablet:flex-col gap-8 tablet:gap-16 basis-[42%] tablet:max-desktop:basis-1/2">
            <span>
                {{ $t('footer-2') }}
            </span>
        </div>

        <div class="flex items-center gap-8">
            <AppLink
                class="app-link"
                external
                :to="{ name: 'index' }"
            >
                <AppIconFacebook
                    size="24"
                    color="#000"
                />
            </AppLink>

            <AppLink
                class="app-link"
                external
                :to="{ name: 'index' }"
            >
                <AppIconInstagram
                    size="24"
                    color="#000"
                />
            </AppLink>

            <AppLink
                class="app-link"
                external
                :to="{ name: 'index' }"
            >
                <AppIconX
                    size="24"
                    color="#000"
                />
            </AppLink>
        </div>
    </div>
</template>
