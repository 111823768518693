<script
    lang="ts"
    setup
>
    const { public: { cabinetUrls } } = useRuntimeConfig()
</script>

<template>
    <div class="flex gap-4">
        <slot name="before-links" />

        <AppLink
            key="login"
            external
            :to="cabinetUrls.login"
        >
            <AppButton secondary>
                {{ $t('login') }}
            </AppButton>
        </AppLink>

        <AppLink
            key="register"
            external
            :to="cabinetUrls.registration"
        >
            <AppButton>
                {{ $t('register') }}
            </AppButton>
        </AppLink>
    </div>
</template>
