<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable @stylistic/max-len -->
            <path
                d="M12 3C10.73 3 9.59999 3.8 9.17999 5H2.99999V7H4.94999L1.99999 14C1.52999 16 2.99999 17 5.49999 17C7.99999 17 9.55999 16 8.99999 14L6.04999 7H9.16999C9.49999 7.85 10.15 8.5 11 8.83V20H1.99999V22H22V20H13V8.82C13.85 8.5 14.5 7.85 14.82 7H17.95L15 14C14.53 16 16 17 18.5 17C21 17 22.56 16 22 14L19.05 7H21V5H14.83C14.4 3.8 13.27 3 12 3ZM12 5C12.2652 5 12.5196 5.10536 12.7071 5.29289C12.8946 5.48043 13 5.73478 13 6C13 6.26522 12.8946 6.51957 12.7071 6.70711C12.5196 6.89464 12.2652 7 12 7C11.7348 7 11.4804 6.89464 11.2929 6.70711C11.1054 6.51957 11 6.26522 11 6C11 5.73478 11.1054 5.48043 11.2929 5.29289C11.4804 5.10536 11.7348 5 12 5ZM5.49999 10.25L6.99999 14H3.99999L5.49999 10.25ZM18.5 10.25L20 14H17L18.5 10.25Z"
                :fill="color"
            />
            <!-- eslint-enable @stylistic/max-len -->
        </template>
    </AppIcon>
</template>
