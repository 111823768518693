<script
    lang="ts"
    setup
>
    const { public: { supportMail } } = useRuntimeConfig()

    const indexNavigationItems = useIndexNavigationItems([ 'tariffs' ])

    const mailToLink = computed<string>(() => {
        return `mailto:${ supportMail }?subject=Landing support`
    })

    const openWidget = (): void => {
        window.SpilkySDK('toggleWidget', 1)
    }
</script>

<template>
    <div class="basis-[54%] flex justify-between max-tablet:flex-wrap gap-8 pt-1 whitespace-nowrap">
        <div class="flex flex-col gap-4">
            <div class="font-medium">
                {{ $t('product') }}
            </div>

            <AppLink
                v-for="item in indexNavigationItems"
                :key="item.name"
                :to="item.to"
                class="app-link"
            >
                {{ $t(item.name) }}
            </AppLink>
        </div>

        <div class="flex flex-col gap-8">
            <div class="flex flex-col gap-4">
                <div class="font-medium">
                    {{ $t('support') }}
                </div>

                <AppLink
                    class="app-link"
                    external
                    :to="mailToLink"
                >
                    {{ supportMail }}
                </AppLink>

                <AppButton
                    wrapper
                    class="app-link"
                    @click="openWidget()"
                >
                    {{ $t('write-here') }}
                </AppButton>
            </div>

            <div class="flex flex-col gap-4">
                <div class="font-medium">
                    {{ $t('comparison') }}
                </div>

                <AppLink
                    class="app-link"
                    :to="{ name: 'comparison-jivo' }"
                >
                    {{ $t('comparison-with') }} Jivo
                </AppLink>

                <AppLink
                    class="app-link"
                    :to="{ name: 'comparison-helpcrunch' }"
                >
                    {{ $t('comparison-with') }} Helpcrunch
                </AppLink>
            </div>
        </div>

        <div class="flex flex-col gap-4">
            <div class="font-medium">
                {{ $t('additional-information') }}
            </div>

            <AppLink
                class="app-link"
                :to="{ name: 'user-agreement' }"
            >
                {{ $t('user-agreement') }}
            </AppLink>

            <AppLink
                class="app-link"
                :to="{ name: 'privacy-policy' }"
            >
                {{ $t('privacy-policy') }}
            </AppLink>
        </div>
    </div>
</template>
