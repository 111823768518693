<script
    lang="ts"
    setup
>
    import type { RouteLocation } from 'vue-router'

    type Props = {
        asButton?: boolean
        to?: RouteLocation
    }

    const props = defineProps<Props>()

    const containerComponent = computed<ReturnType<typeof defineComponent>>(() => {
        return props.asButton ? 'button' : resolveComponent('AppLink')
    })
</script>

<template>
    <component
        :is="containerComponent"
        :to="props.to"
        wrapper
        class="app-link-with-bg !justify-start"
    >
        <div class="p-3 bg-stone rounded-[8px]">
            <slot
                name="icon"
                :size="20"
                :color="'#000'"
            />
        </div>

        <div class="ml-3 text-[20px] font-medium truncate">
            <slot />
        </div>

        <slot name="after-text" />
    </component>
</template>
