<script
    lang="ts"
    setup
>
    const indexNavigationItems = useIndexNavigationItems()

    const indexNavigationItemsDetails = computed(() => {
        return {
            'chat': {
                subtitle: 'header-menu-1',
                iconComponent: resolveComponent('AppIconDialogs'),
            },
            'knowledge-base': {
                subtitle: 'header-menu-2',
                iconComponent: resolveComponent('AppIconBookshelfChat'),
            },
            'widget': {
                subtitle: 'header-menu-3',
                iconComponent: resolveComponent('AppIconWidget'),
            },
        }
    })
</script>

<template>
    <AppDropdown
        width="380"
        content-class="!rounded-[12px]"
    >
        <template #activator="{ active, toggle }">
            <button
                :class="[
                    'app-link-with-bg gap-2 text-[14px] font-medium',
                    { '!bg-action-active': active },
                ]"
                @click="toggle('')"
            >
                {{ $t('opportunities') }}

                <AppIconChevronDown
                    size="16"
                    :rotate="active"
                    :class="{ '[&>path]:fill-black': active }"
                />
            </button>
        </template>

        <template #default="{ close }">
            <div class="flex flex-col gap-2">
                <AppLink
                    v-for="item in indexNavigationItems"
                    :key="item.name"
                    :to="item.to"
                    class="app-link-with-bg items-start justify-start gap-4 text-[14px] font-medium"
                    @click="close()"
                >
                    <div class="p-3 bg-stone rounded-[8px]">
                        <component
                            :is="indexNavigationItemsDetails[item.name].iconComponent"
                            name="icon"
                            :size="16"
                            :color="'#000'"
                        />
                    </div>

                    <div class="flex flex-col gap-1">
                        <div class="text-[16px]">
                            {{ $t(item.name) }}
                        </div>

                        <AppHtmlContent
                            :content="$t(indexNavigationItemsDetails[item.name].subtitle)"
                            class="text-[14px] text-secondary-text font-normal leading-[120%] whitespace-normal"
                        />
                    </div>
                </AppLink>
            </div>
        </template>
    </AppDropdown>
</template>
