<template>
    <div class="hidden desktop:flex items-center whitespace-nowrap gap-2">
        <TheHeaderNavigationItemOpportunities />

        <TheHeaderNavigationItemComporison />

        <AppLink
            :to="{ name: 'tariffs' }"
            class="app-link-with-bg text-[14px] font-medium"
        >
            {{ $t('tariffs') }}
        </AppLink>
    </div>
</template>
