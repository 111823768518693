<template>
    <footer
        class="
            relative
            flex
            flex-col
            pt-[132px]
            pb-[48px]
            px-[32px]
            tablet:px-[120px]
            leading-[150%]
            bg-primary
        "
    >
        <div class="absolute top-0 left-0 w-full h-[42px] rounded-b-[100px] bg-light-stone"></div>

        <div class="flex flex-col desktop:flex-row gap-8 mb-[74px] pb-1.5">
            <TheFooterSideLeft />

            <div
                class="
                    desktop:w-[1px]
                    tablet:max-desktop:h-[1px]
                    desktop:mx-auto
                    tablet:max-desktop:my-auto
                    bg-[#D4BC3E]
                "
            ></div>

            <TheFooterSideRight />
        </div>

        <TheFooterBottom />
    </footer>
</template>
