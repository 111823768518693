<script
    lang="ts"
    setup
>
    type Props = {
        disabled?: boolean
        active?: boolean
        inactiveColor?: string
    }

    const props = withDefaults(defineProps<Props>(), {
        disabled: false,
        active: false,
        inactiveColor: '#000',
    })

    const style = useCssModule()

    const dropdownItemClass = computed<string[]>(() => {
        const classes = [ style['dropdown-item'] ]

        if (props.disabled) {
            classes.push(style['dropdown-item--disabled'])
        }

        if (props.active) {
            classes.push(style['dropdown-item--active'])
        }

        return classes
    })

    const dropdownItemStyle = computed<string>(() => {
        return '--inactive-color: ' + props.inactiveColor
    })
</script>

<template>
    <div
        :data-active="props.active || undefined"
        :class="dropdownItemClass"
        :style="dropdownItemStyle"
    >
        <slot />
    </div>
</template>

<style
    lang="sass"
    module
    scoped
>
    .dropdown-item
        display: flex
        align-items: center
        width: 100%
        min-height: 36px
        padding: 8px
        font-size: 14px
        font-weight: 400
        line-height: 130%
        color: var(--inactive-color)
        border-radius: 8px
        cursor: pointer
        user-select: none

        &:not(:last-of-type)
            margin-bottom: 4px

        &--disabled
            pointer-events: none
            color: #a7a7a7
            cursor: not-allowed

        &:hover,
        &--active
            color: #000

        &:hover:not(&--active)
            background: theme('colors.action.hover')

        &--active
            background: theme('colors.action.active')
</style>
