<script
    lang="ts"
    setup
>
    type Emit = {
        (event: 'open'): void
    }

    const emit = defineEmits<Emit>()
</script>

<template>
    <button
        class="
            app-link-with-bg
            desktop:!hidden
            !py-[2px]
            !px-1
        "
        @click="emit('open')"
    >
        <AppIconMenu
            size="34"
            color="#000"
        />
    </button>
</template>
