<script
    lang="ts"
    setup
>
    type Emit = {
        (event: 'close'): void
    }

    type SubMenu = 'opportunities' | 'comparison'

    const emit = defineEmits<Emit>()

    const subMenu = ref<SubMenu | undefined>()

    const indexNavigationItems = useIndexNavigationItems()
    const indexNavigationItemsIcons = {
        'chat': resolveComponent('AppIconDialogs'),
        'knowledge-base': resolveComponent('AppIconBookshelfChat'),
        'widget': resolveComponent('AppIconWidget'),
    }

    defineExpose({ subMenu })
</script>

<template>
    <div class="flex flex-col gap-2 w-full">
        <button
            v-if="subMenu"
            key="back"
            class="app-link-with-bg self-start gap-1 mt-2 group"
            @click="subMenu = undefined"
        >
            <AppIconChevronRight
                :rotate="true"
                class="group-hover:[&>path]:fill-black [&>path]:transition-colors"
            />

            <span class="text-[16px] text-secondary-text font-medium group-hover:text-black transition-colors">
                {{ $t('back') }}
            </span>
        </button>

        <template v-if="subMenu === 'opportunities'">
            <TheHeaderActionsSidebarNavigationItem
                v-for="item in indexNavigationItems"
                :key="item.name"
                :to="item.to"
                @click="emit('close')"
            >
                <template #icon="slotProps">
                    <component
                        :is="indexNavigationItemsIcons[item.name]"
                        v-bind="slotProps"
                    />
                </template>

                {{ $t(item.name) }}
            </TheHeaderActionsSidebarNavigationItem>
        </template>

        <template v-else-if="subMenu === 'comparison'">
            <TheHeaderActionsSidebarNavigationItem
                key="jivo"
                :to="{ name: 'comparison-jivo' }"
                @click="emit('close')"
            >
                <template #icon>
                    <div class="relative w-[20px] h-[20px]">
                        <AppPublicImage
                            file="tariffs-2"
                            width="30"
                            height="30"
                            class="absolute top-[calc(50%-15px)] left-[calc(50%-15px)] min-h-[30px] min-w-[30px]"
                        />
                    </div>
                </template>

                {{ $t('comparison-with') }} Jivo
            </TheHeaderActionsSidebarNavigationItem>

            <TheHeaderActionsSidebarNavigationItem
                key="helpcrunch"
                :to="{ name: 'comparison-helpcrunch' }"
                @click="emit('close')"
            >
                <template #icon>
                    <div class="relative w-[20px] h-[20px]">
                        <AppPublicImage
                            file="tariffs-3"
                            class="absolute top-[calc(50%-15px)] left-[calc(50%-15px)] min-h-[30px] min-w-[30px]"
                        />
                    </div>
                </template>

                {{ $t('comparison-with') }} Helpcrunch
            </TheHeaderActionsSidebarNavigationItem>
        </template>

        <template v-else>
            <TheHeaderActionsSidebarNavigationItem
                key="opportunities"
                as-button
                class="mt-14 pr-2 group"
                @click="subMenu = 'opportunities'"
            >
                <template #icon="slotProps">
                    <AppIconLightningBoltOutline v-bind="slotProps" />
                </template>

                {{ $t('opportunities') }}

                <template #after-text>
                    <AppIconChevronRight class="ml-auto group-hover:[&>path]:fill-black [&>path]:transition-colors" />
                </template>
            </TheHeaderActionsSidebarNavigationItem>

            <TheHeaderActionsSidebarNavigationItem
                key="comparison"
                as-button
                class="pr-2 group"
                @click="subMenu = 'comparison'"
            >
                <template #icon="slotProps">
                    <AppIconScaleBalance v-bind="slotProps" />
                </template>

                {{ $t('comparison') }}

                <template #after-text>
                    <AppIconChevronRight class="ml-auto group-hover:[&>path]:fill-black [&>path]:transition-colors" />
                </template>
            </TheHeaderActionsSidebarNavigationItem>

            <TheHeaderActionsSidebarNavigationItem
                key="tariffs"
                :to="{ name: 'tariffs' }"
                @click="emit('close')"
            >
                <template #icon="slotProps">
                    <AppIconUsdPrice v-bind="slotProps" />
                </template>

                {{ $t('tariffs') }}
            </TheHeaderActionsSidebarNavigationItem>
        </template>
    </div>
</template>
