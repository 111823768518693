<template>
    <AppIcon v-bind="$props">
        <!-- eslint-disable -->
        <g clip-path="url(#clip0_14688_20316)">
            <rect x="1.5" y="4.5" width="21" height="15" rx="3" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 4.5H10.5V11.5H1.5V4.5Z" fill="#1A47B8"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 4.5V5.5H22.5V4.5H10.5ZM10.5 6.5V7.5H22.5V6.5H10.5ZM10.5 8.5V9.5H22.5V8.5H10.5ZM10.5 10.5V11.5H22.5V10.5H10.5ZM1.5 12.5V13.5H22.5V12.5H1.5ZM1.5 14.5V15.5H22.5V14.5H1.5ZM1.5 16.5V17.5H22.5V16.5H1.5ZM1.5 18.5V19.5H22.5V18.5H1.5Z" fill="#F93939"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 5.5V6.5H3.5V5.5H2.5ZM4.5 5.5V6.5H5.5V5.5H4.5ZM6.5 5.5V6.5H7.5V5.5H6.5ZM8.5 5.5V6.5H9.5V5.5H8.5ZM7.5 6.5V7.5H8.5V6.5H7.5ZM5.5 6.5V7.5H6.5V6.5H5.5ZM3.5 6.5V7.5H4.5V6.5H3.5ZM2.5 7.5V8.5H3.5V7.5H2.5ZM4.5 7.5V8.5H5.5V7.5H4.5ZM6.5 7.5V8.5H7.5V7.5H6.5ZM8.5 7.5V8.5H9.5V7.5H8.5ZM2.5 9.5V10.5H3.5V9.5H2.5ZM4.5 9.5V10.5H5.5V9.5H4.5ZM6.5 9.5V10.5H7.5V9.5H6.5ZM8.5 9.5V10.5H9.5V9.5H8.5ZM7.5 8.5V9.5H8.5V8.5H7.5ZM5.5 8.5V9.5H6.5V8.5H5.5ZM3.5 8.5V9.5H4.5V8.5H3.5Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_14688_20316">
                <rect x="1.5" y="4.5" width="21" height="15" rx="3" fill="white"/>
            </clipPath>
        </defs>
        <!-- eslint-enable -->
    </AppIcon>
</template>
