<template>
    <AppIcon v-bind="$props">
        <!-- eslint-disable -->
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_13020_112477)">
                <g filter="url(#filter0_d_13020_112477)">
                    <g clip-path="url(#clip1_13020_112477)">
                        <rect x="1.5" y="4.5" width="21" height="15" rx="3" fill="white"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 14.5H22.5V19.5H1.5V14.5Z" fill="white"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 4.5H22.5V9.5H1.5V4.5Z" fill="white"/>
                    </g>
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_d_13020_112477"
                    x="-1.5"
                    y="3.5"
                    width="27"
                    height="21"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2"/>
                    <feGaussianBlur stdDeviation="1.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13020_112477"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13020_112477" result="shape"/>
                </filter>
                <clipPath id="clip0_13020_112477">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
                <clipPath id="clip1_13020_112477">
                    <rect x="1.5" y="4.5" width="21" height="15" rx="3" fill="white"/>
                </clipPath>
            </defs>
        </svg>
        <!-- eslint-enable -->
    </AppIcon>
</template>
