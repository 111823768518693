export const isUserUkrainian = (): boolean | undefined => {
    if (import.meta.client) {
        try {
            return [ 'Europe/Kiev', 'Europe/Kyiv' ].includes(Intl.DateTimeFormat().resolvedOptions().timeZone)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
            /* empty */
        }
    }
}
