<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable @stylistic/max-len -->
            <path
                d="M7.27119 12.652H9.46351V21.6774C9.46351 21.8556 9.6079 22 9.78609 22H13.5033C13.6814 22 13.8258 21.8556 13.8258 21.6774V12.6945H16.3461C16.51 12.6945 16.6478 12.5715 16.6665 12.4088L17.0493 9.08606C17.0598 8.99465 17.0309 8.9031 16.9697 8.83452C16.9085 8.76587 16.8209 8.72658 16.7289 8.72658H13.826V6.64374C13.826 6.01587 14.164 5.69748 14.8309 5.69748C14.9259 5.69748 16.7289 5.69748 16.7289 5.69748C16.9071 5.69748 17.0515 5.55303 17.0515 5.3749V2.32497C17.0515 2.14677 16.9071 2.00239 16.7289 2.00239H14.1131C14.0947 2.00148 14.0537 2 13.9933 2C13.5394 2 11.9618 2.0891 10.7156 3.23555C9.33487 4.506 9.5268 6.02716 9.57267 6.2909V8.72652H7.27119C7.093 8.72652 6.94861 8.8709 6.94861 9.0491V12.3294C6.94861 12.5075 7.093 12.652 7.27119 12.652Z"
                :fill="color"
            />

            <!-- eslint-enable @stylistic/max-len -->
        </template>
    </AppIcon>
</template>
