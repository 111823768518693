<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <path
                d="M3 6H21V8H3V6ZM3 11H21V13H3V11ZM3 16H13V18H3V16Z"
                :fill="color"
            />
        </template>
    </AppIcon>
</template>
