<script
    lang="ts"
    setup
>
    import type { RouteLocationNamedRaw } from 'vue-router'

    type Item = {
        title: string
        subtitle: string
        file: string
        to: RouteLocationNamedRaw
    }

    const items: Item[] = [
        {
            title: 'Jivo',
            subtitle: 'comparison-1',
            file: 'tariffs-2',
            to: { name: 'comparison-jivo' },
        },
        {
            title: 'Helpcrunch',
            subtitle: 'comparison-25',
            file: 'tariffs-3',
            to: { name: 'comparison-helpcrunch' },
        },
    ]
</script>

<template>
    <AppDropdown
        width="380"
        content-class="!rounded-[12px]"
    >
        <template #activator="{ active, toggle }">
            <button
                :class="[
                    'app-link-with-bg gap-2 text-[14px] font-medium',
                    { '!bg-action-active': active },
                ]"
                @click="toggle('')"
            >
                {{ $t('comparison') }}

                <AppIconChevronDown
                    size="16"
                    :rotate="active"
                    :class="{ '[&>path]:fill-black': active }"
                />
            </button>
        </template>

        <template #default="{ close }">
            <div class="flex flex-col gap-2">
                <AppLink
                    v-for="item in items"
                    :key="item.title"
                    :to="item.to"
                    class="app-link-with-bg items-start justify-start gap-4 text-[14px] font-medium"
                    @click="close()"
                >
                    <div class="relative w-[40px] h-[40px] p-3 bg-stone rounded-[8px]">
                        <AppPublicImage
                            :file="item.file"
                            class="
                                absolute
                                top-[calc(50%-15px)]
                                left-[calc(50%-15px)]
                                min-h-[30px]
                                min-w-[30px]
                                w-[30px]
                                h-[30px]
                            "
                        />
                    </div>

                    <div class="flex flex-col gap-1">
                        <div class="text-[16px]">
                            {{ $t('comparison-with') }} {{ item.title }}
                        </div>

                        <AppHtmlContent
                            :content="$t(item.subtitle)"
                            class="text-[14px] text-secondary-text font-normal leading-[120%] whitespace-normal"
                        />
                    </div>
                </AppLink>
            </div>
        </template>
    </AppDropdown>
</template>
