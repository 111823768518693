<script
    lang="ts"
    setup
>
    type Emit = {
        (event: 'close'): void
    }

    const emit = defineEmits<Emit>()

    const navigationRef = ref<ReturnType<typeof defineComponent>>()

    const { tablet } = useWindowSize()

    const showPromo = computed<boolean>(() => !navigationRef.value.subMenu)
</script>

<template>
    <aside
        class="
            z-50
            overflow-hidden
            fixed
            items-center
            top-0
            right-0
            bottom-0
            flex
            flex-col
            min-w-[340px]
            tablet:min-w-[360px]
            max-tablet:max-w-[340px]
            p-4
            bg-[rgba(246,247,248,0.9)]
            rounded-[0_0_0_24px]
            shadow-[rgb(0,0,0,0.22)_-0.5rem_0_1.5rem_-1.25rem]
            before:content-['']
            before:z-[-1]
            before:absolute
            before:inset-0
            before:backdrop-blur-xl
        "
    >
        <div class="flex justify-end w-full">
            <button
                class="
                    app-link-with-bg
                    desktop:!hidden
                    !py-[2px]
                    !px-1
                "
                @click="emit('close')"
            >
                <AppIconClose
                    size="34"
                    color="#000"
                />
            </button>
        </div>

        <TheHeaderActionsSidebarNavigation
            ref="navigationRef"
            key="navigation"
            @close="emit('close')"
        />

        <div
            v-if="tablet && showPromo"
            key="auth"
            class="flex flex-col items-center gap-6 mt-auto"
        >
            <AppPublicImage
                file="sidebar-1"
                class="tablet:h-[180px]"
            />

            <div class="max-w-[320px] text-[20px] font-medium text-center">
                {{ $t('sidebar-1') }}
            </div>
        </div>

        <TheHeaderActionsAuthButtons
            class="
                mt-auto
                max-tablet:order-3
                max-tablet:flex-col
                max-tablet:!gap-2
                max-tablet:w-full
                max-tablet:[&>a>button]:w-full
            "
        >
            <template #before-links>
                <TheHeaderActionsLanguage
                    key="language"
                    up
                    class="mb-2 self-center tablet:hidden"
                />
            </template>
        </TheHeaderActionsAuthButtons>

        <TheHeaderActionsLanguage
            key="language"
            up
            class="mb-2 mt-10 max-tablet:hidden"
        />
    </aside>
</template>
