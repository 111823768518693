<template>
    <AppIcon v-bind="$props">
        <g clip-path="url(#clip0_12624_17746)">
            <rect
                x="1.5"
                y="4.5"
                width="21"
                height="15"
                rx="3"
                fill="#FFDA2C"
            />

            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.5 4.5H22.5V12.5H1.5V4.5Z"
                fill="#3A99FF"
            />
        </g>

        <defs>
            <clipPath id="clip0_12624_17746">
                <rect
                    x="1.5"
                    y="4.5"
                    width="21"
                    height="15"
                    rx="3"
                    fill="white"
                />
            </clipPath>
        </defs>
    </AppIcon>
</template>
