<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable @stylistic/max-len -->
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3 3H16C16.5948 3 17 3.40169 17 4V13C17 13.5983 16.5948 14 16 14H6C5.74863 14 5.50518 14.0093 5.31182 14.1708L2.82476 16.2954C2.50026 16.5726 2 16.3421 2 15.9153V4C2 3.40169 2.40523 3 3 3ZM20.9231 8H19V15C19 15.5523 18.5523 16 18 16H7V17.6667C7 18.265 7.48215 18.75 8.07692 18.75H16.7221C16.9735 18.75 17.217 18.8385 17.4103 19L21.2264 21.4944C21.559 21.7117 22 21.4731 22 21.0759V9.08333C22 8.48502 21.5178 8 20.9231 8Z"
                :fill="color"
            />
            <!-- eslint-enable @stylistic/max-len -->
        </template>
    </AppIcon>
</template>
