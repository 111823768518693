<template>
    <div class="basis-[32%] flex flex-col">
        <AppLink
            :to="{ name: 'index' }"
            class="app-link mb-8"
        >
            <AppBranding
                size="200"
                color="#000"
                height="34"
                class="!min-h-[34px]"
            />
        </AppLink>

        <div class="flex flex-col gap-4">
            <div>
                {{ $t('footer-1') }}
            </div>
        </div>
    </div>
</template>
